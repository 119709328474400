<template>
    <modal ref="modalCrearBasadoExistente" titulo="Seleccionar tipo existente" class="modal-crear-tipo-existente">
        <div v-for="(data,i) in tipos" :key="i" class="row justify-content-center">
            <div class="tipo-anuncio col-10 px-1 py-3" @click="seleccionarExistente(data.id)">
                <div class="d-flex my-auto">
                    <div class="circle-icon rounded-circle d-middle-center" :style="`background: ${data.color ? data.color : '#dbdbdb'}`">
                        <i :class="`f-15 op-05 ${data.icono ? data.icono : ''}`" />
                    </div>
                    <p class="f-15 my-auto ml-2"> {{ data.nombre }} </p>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
export default {
    data(){
        return{
            tipoAnuncios:[
                {
                    id: 0,
                    nombre: 'Ventas y emprendimientos',
                    color: '#FFD54F',
                    icono: 'icon-shop-outline',
                    tiempo: 15,
                    tipo: 0
                },
                {
                    id: 1,
                    nombre: 'Trabajos y servicios',
                    color: '#B2B2FF',
                    icono: 'icon-like',
                    tiempo: 20,
                    tipo: 0
                },
                {
                    id: 2,
                    nombre: 'Noticias y anuncios',
                    color: '#93EDCF',
                    icono: 'icon-book',
                    tiempo: 45,
                    tipo: 0
                },
                {
                    id: 3,
                    nombre: 'Elementos perdidos',
                    color: '#FFB2C2',
                    icono: 'icon-help-circle-outline',
                    tiempo: 5,
                    tipo: 0
                },
                {
                    id: 4,
                    nombre: 'Mantenimiento',
                    color: '#80DCFF',
                    icono: 'icon-tools',
                    tiempo: 30,
                    tipo: 0
                },
                {
                    id: 5,
                    nombre: 'Vehiculos',
                    color: '',
                    icono: 'icon-car',
                    tiempo: 10,
                    tipo: 0
                }
            ],
            tipo:{
              nombre : "Diversión",
              vigencia : 8,
              dinero : 1,
              icono : "class-icon",
              color : "#FF0000"
            },
            tipos: []
        }
    },
    methods:{
        toggle(tipos){
            this.tipos = tipos
            this.$refs.modalCrearBasadoExistente.toggle()
        },
        seleccionarExistente(id_tipo){
            this.$refs.modalCrearBasadoExistente.toggle()
            this.$router.push(`/configurar/tipo_anuncios/guardar/${id_tipo}?existente`)
        }
    }
}
</script>
<style lang="scss" scoped>
.modal-crear-tipo-existente{
    .circle-icon{
        min-width: 32px;
        min-height: 32px;
        max-width: 32px;
        max-height: 32px;
    }
    .h-21{
        max-height: 22px;
        min-height: 22px;
    }
    i.icon-book{
        &::before{
            margin-left: 0;
        }
    }
    .tipo-anuncio{
        border-bottom: 1px solid var(--color-db);
        &:hover{
            background: #F4F5F7;
            cursor: pointer;
        }
    }
}
</style>